import React from 'react';
import './assets/App.css';

import Home from './pages/Home';

export default function App() {
  return (
    <>
      <Home />
    </>
  );
}
